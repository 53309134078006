import React from "react";
import { Link } from "gatsby";
import useGlossary from "../../hooks/useGlossary";
import useBlocksData from "../../hooks/useBlocksData";
//import { pipesToSpan } from "../../util/common";
import SectionCardsAccross from "../blocks/section-cards-across";

const findGlossaryTerm = (heystuck, needle) => {
  //console.log("needle", needle);
  var url = new URL(needle);
  var pathname = url.pathname.endsWith("/")
    ? url.pathname.slice(0, -1)
    : url.pathname;
  return heystuck.find((node) => node.path.alias === pathname);
};

const ArticlesOfMetrics = ({ metrics, title }) => {
  const { glossaries } = useGlossary();

  const articles0 = metrics
    .map((m) =>
      m.link_to_glossary
        ? findGlossaryTerm(glossaries, m.link_to_glossary)
        : false
    )
    .filter((m) => m);

  const articles = [];

  articles0.forEach((art) => {
    const all = articles.map((a) => a.title);
    //console.log( all)
    if (!all.includes(art.title)) {
      articles.push(art);
    }
  });

  const items = articles;

  return <Articles items={items} title={title} />;
};

const ArticlesByUrl = ({ url, title }) => {
  const { glossaries } = useGlossary();

  if (!url) return;

  const link_to_glossary = url;

  var glossaryTerm = link_to_glossary
    ? findGlossaryTerm(glossaries, link_to_glossary)
    : false;

  const articles = glossaryTerm ? [glossaryTerm] : [];

  return <Articles items={articles} title={title} />;
};

const Articles = ({ items, title }) => {
  if (items.length === 0) return;

  const rows = items
    .map((item) => ({
      ...item,
      path: {
        alias: item.path.alias.endsWith("/")
          ? item.path.alias
          : item.path.alias + "/",
      },
    }))
    .map((item, index) => (
      <Link to={item.path.alias} key={index}>
        <div className="border rounded-lg p-5 border-[#E9EFF6] h-full">
          <div className="mb-5 h-[24px]">
            <span className="rounded-[2px] px-5 py-1 bg-[#E8EDFF] text-blue  font-medium">
              Glossary
            </span>
          </div>
          <div className="text-lg font-bold mb-2">{item.title}</div>
          <div className="text-gray-700 text-sm">{item.description}</div>
        </div>
      </Link>
    ));

  return (
    <div className="space-y-4">
      <h3 className="text-xl font-medium ">{title}</h3>
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-2">{rows}</div>
    </div>
  );
};

const HowItWorksBlock = ({ context, ...props }) => {
  const { block1 } = useBlocksData();
  return (
    <Component data={block1} context={context} {...props}  />
  )

};

const WhatIsItBlock = ({ children }) => {
  const { block2 } = useBlocksData();

  const data = block2;

  const d = data.relationships.content;
  const { title, description_html, cta } = d;
  // const image = {
  //   src: d.relationships.image.localFile.publicURL,
  //   alt: d.image.alt,
  // };

  const cta0 = { ...data.cta0, uri: data.cta0.uri.replace("internal:", "") };

  //console.log(cta0 )

  //const t = 'Sightfull is an |automated GTM analytics platform.|'

  //const titleHtml = pipesToSpan(title);
//   const titleHtml = pipesToSpan(t , 'text-[#A4B8FF]', {
//     background: 'linear-gradient(279.42deg, #FFFFFF 5.56%, #A4B8FF 22.13%, #FFFFFF 123.4%)'
//   });



  return (
    <div className="max-w-screen-xl mx-auto pt-[40px] md:pt-[106px] pb-[40px] md:pb-[80px]  px-6 md:px-[80px] rounded-[10px] md:rounded-[20px] md:bg-cover bg-auto bg-center bg-[url('/background/bg-blue-mobile.jpg')] md:bg-[url('/background/bg-blue-desktop.jpg')]">
      
        <div className="space-y-8 lg:max-w-[680px] mx-auto text-center">
          <h2 className="text-[40px] lg:text-[50px] leading-[44px] lg:leading-[55px] tracking-[-1px] font-semibold text-white"
          dangerouslySetInnerHTML={{ __html: title }}
          />
           
          <div
            className="text-base leading-[24px] font-medium text-white"
            dangerouslySetInnerHTML={{ __html: description_html.processed }}
          />
          <div className="flex flex-row flex-wrap justify-center gap-3">
            <WhatIsItBlock.Button
              cta={cta0}
              className="bg-[#042185] border-[#042185] text-white"
            />

            <WhatIsItBlock.Button
              cta={cta}
              className="text-white border-white"
            />
          </div>
        </div>
      
    </div>
  );
};

WhatIsItBlock.Button = ({ cta, className }) => {
  return (
    <Link
      to={cta.uri}
      className={
        "py-[13px] px-6 border leading-[20px] block font-medium rounded " +
        className
      }
    >
      {cta.title}
    </Link>
  );
};


const Component = ({ data, context, ...props }) => {
  //console.log( data )
  let comp = "";

  switch (data.__typename) {
    

    case "block_content__section_cards_across":
      comp = <SectionCardsAccross data={data} context={context} {...props} />;
      break;

    default:
      comp = <pre>{JSON.stringify(data, null, 3)}</pre>;
      break;
  }

  return <>{comp}</>;
};




export { ArticlesOfMetrics, ArticlesByUrl, HowItWorksBlock, WhatIsItBlock };
