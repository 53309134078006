import React from "react";

const SearchInput = (
  {
    onChange,
    value,
    className = "py-2.5 pl-10 pr-3 border-[#D7DFE9] hover:border-[#AFBACA] focus:border-[#8897AE] outline-none text-[#455468] bg-white w-full rounded border hover:border  text-base leading-[20px] data-hj-allow",
    placeholder = "Search",
    ...props
  },
  
) => {
  return (
    <input
      type="search"
      placeholder={placeholder}
      className={className}
      onChange={onChange}
      value={value}
      style={{
        background:
          "url('/search-large.svg') no-repeat  10px center, url('/line.svg') no-repeat 32px center, #fff",
      }}
      {...props}
    />
  );
};

export default SearchInput;
