import React, { useState } from "react";
import { UserCommentForm } from "../marketplace/comment";

const RequestNew = ({ title, form_title = "" }) => {
  const [show, setShow] = useState(false);

  const handleClick = () => {
    setShow(true);
  };

  const handleClose = () => {
    setShow(false);
  };

  return show ? (
    <div className="mt-4">
      <UserCommentForm show={show} title={form_title} onClose={handleClose} />
    </div>
  ) : (
    <div className="mt-4 p-2 border inline-block bg-[#E9EFF688] rounded-[8px] px-10 py-6 space-x-5">
      <button
        className="flex flex-row justify-start items-center gap-4"
        onClick={handleClick}
      >
        <span className="bg-[#E8EDFF] text-[#4A72FF] rounded-full text-xl w-8 h-8 border flex flex-row items-center justify-around">
          +
        </span>
        <span className="text-base text-[#3D434C] font-medium leading-[24px]">
          {title}
        </span>
      </button>
    </div>
  );
};

export default RequestNew;
