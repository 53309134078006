import React from "react";
import arrowIcon4 from "../../images/arrow-4.svg";
import arrowIcon5 from "../../images/arrow-5.svg";

const SectionCardsAccross = ({ data, context, ...props }) => {
  if (context === "integration") {
    return (
      <SectionCardsAccrossInegration data={data} context={context} {...props} />
    );
  }

  return (
    <SectionCardsAccrossDefault data={data} context={context} {...props} />
  );
};

const SectionCardsAccrossInegration = ({ data, context, ...props }) => {
  //console.log('props=',  props )
  const { title } = data;
  const items = data.relationships.items;
  const className = props.className;

  const cards = items
    .map((item, index) => ({
      //title: item.title,
      title: `0${index + 1}`,
      description: item.description,
      image: {
        src: item?.relationships?.image?.localFile?.publicURL,
        alt: item?.image?.alt,
      },
    }))
    .map((item, index) => <SectionCardsAccross.CardV2 {...item} key={index} />);

  return (
    <div className={className}>
      <h3 className="text-center sm:text-left text-[36px] md:text-[72px] leading-[76px] tracking-[-0.03em] font-medium text-[#3D434C] ">
        {title}
      </h3>
      <div className="flex flex-col sm:flex-row print:sm:flex-row print:flex-row justify-start gap-16 md:gap-10 lg:gap-[30px]  ">
        {cards}
      </div>
    </div>
  );
};

const SectionCardsAccrossDefault = ({ data, context, ...props }) => {
  //console.log('props=',  props )
  const { title } = data;
  const items = data.relationships.items;
  const className = props.className;

  const cards0 = items
    .map((item, index) => ({
      //title: item.title,
      title: `0${index + 1}`,
      description: item.description,
      image: {
        src: item?.relationships?.image?.localFile?.publicURL,
        alt: item?.image?.alt,
      },
    }))
    .map((item, index) => <SectionCardsAccross.Card {...item} key={index} />);

  const cards = [];

  for (const key in cards0) {
    cards.push(cards0[key]);
    cards.push(<SectionCardsAccross.Arrow key={"s" + key} />);
  }

  cards.pop();

  return (
    <div className={className}>
      <h2 className="text-center sm:text-left text-xl leading-[28px] font-medium text-[#2D3643] ">
        {title}
      </h2>
      <div className="flex flex-col sm:flex-row print:sm:flex-row print:flex-row justify-between gap-10 lg:gap-0  ">
        {cards}
      </div>
    </div>
  );
};

SectionCardsAccross.Card = ({ title, description, image }) => {
  return (
    <div className="text-center space-y-2.5 ">
      <div className=" flex items-center justify-center h-[80px] w-[88px] mx-auto">
        <img src={image.src} alt={image.alt} className="inline-block" />
      </div>
      <div className="space-y-2.5 md:max-w-[180px]">
        <div className="text-base lg:text-sm font-medium text-[#8897AE] leading-[20px]">
          {title}
        </div>

        <div className="text-sm font-normal text-[#1C222B] leading-[17px] ">
          {description}
        </div>
      </div>
    </div>
  );
};

SectionCardsAccross.Arrow = () => {
  return (
    <div className="hidden lg:flex    flex-row items-center justify-center h-[80px]">
      <img
        src={arrowIcon4}
        alt="arrow"
        //height="16"
        //width="16"
        className="block mx-auto mt-[16px] "
      />
    </div>
  );
};

SectionCardsAccross.CardV2 = ({ title, description, image }) => {
  return (
    <div className="text-center md:text-left space-y-2.5 min-w-[207px]">
      <div className=" flex items-center  justify-around md:justify-start h-[100px] w-[108px] mx-auto md:mx-0">
        <img src={image.src} alt={image.alt} className="inline-block" />
      </div>
      <div className="space-y-4 md:max-w-[180px]">
        <SectionCardsAccross.ArrowV2 />

        <div className="text-lg font-normal text-[#3D434C] leading-[22px] ">
          {description}
        </div>
      </div>
    </div>
  );
};

SectionCardsAccross.ArrowV2 = () => {
  return (
    <div className="flex flex-row items-center justify-around md:justify-start  ">
      <img
        src={arrowIcon5}
        alt="arrow"
        //height="16"
        //width="16"
        className="inline-block  "
      />
    </div>
  );
};

export default SectionCardsAccross;
