import { graphql, useStaticQuery } from "gatsby";

//const isProduction = `${process.env.GATSBY_ACTIVE_ENV}` === "production";

const useBlocksData = () => {
  const data = useStaticQuery(graphql`
    query {
      block1: blockContentSectionCardsAcross(
        id: { eq: "6dd03dbb-4c80-5f7f-a9b1-baa702711370" }
      ) {
        __typename
        id
        info
        title: field_title
        relationships {
          items: field_items {
            __typename
            title: field_title
            description: field_description
            image: field_image {
              alt
              height
              width
            }
            relationships {
              image: field_image {
                localFile {
                  publicURL
                }
              }
            }
          }
        }
      }
      block2: blockContentSectionContent(
        id: { eq: "b486ad29-7764-5dfa-9610-f7673fe53205" }
      ) {
        __typename
        id
        info
        cta0: field_primary_cta {
          title
          uri
        }
        relationships {
          content: field_content {
            ...ParagraphGenericBlock
          }
        }
      }
    }
  `);

  return {
    block1: data.block1,
    block2: data.block2,
  };
};

export default useBlocksData;
