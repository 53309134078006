import React, { useState, useMemo } from "react";
import { Link, graphql } from "gatsby";
import Layout from "../components/layout";
import getMetatags from "../util/getMetatags";
import Seo from "../components/Seo";
import {
  HowItWorksBlock,
  WhatIsItBlock,
} from "../components/marketplace/blocks";
import Metric, {
  MetricPreview,
  BuildYourOwn,
} from "../components/marketplace/metric";
import Template from "../components/marketplace/template-card";
import Pagination, { PaginationV2 } from "../components/marketplace/pagination";
import SearchInput from "../components/marketplace/search-input";
import RequestNew from "../components/buttons/request-new";
import { RequestForm, Modal } from "../components/forms/request-form";
import BlockContentSectionContent from "../components/blocks/content-section";


//const isProduction = `${process.env.GATSBY_ACTIVE_ENV}` === "production";

function IntegrationsPage({
  data: { node, metrics, templates, noResults },
  path,
  ...props
}) {
  //console.log( noResults)
  const seo = getMetatags(
    node.metatag
    //node.relationships.OGImage?.localFile?.publicURL
  );

  const [sq, setSq] = useState("");

  const handleChange = (e) => {
    setSq(e.target.value);
  };

  const createBase = (metrics, templates, integrationId) => {
    const vocsNames = [
      { vocabulary: "category", name: "Category" },
      { vocabulary: "persona", name: "User" },
      { vocabulary: "workflows", name: "Processes" },
      { vocabulary: "connectors", name: "Integration" },
    ];

    const generateMetricTextToSearch = (metric) => {
      const text = [];
      text.push(metric.title);
      text.push(
        metric.one_liner ? metric.one_liner.replace(/(<([^>]+)>)/gi, "") : ""
      );

      if (metric?.description) {
        text.push(metric.description.replace(/(<([^>]+)>)/gi));
      }
      if (metric.alternative_names && metric.alternative_names.length) {
        metric.alternative_names.forEach((name) => {
          text.push(name);
        });
      }

      for (const v in vocsNames) {
        const voc = vocsNames[v].vocabulary;
        if (metric[voc]) {
          for (const x in metric[voc]) {
            text.push(metric[voc][x].name);
          }
        }
      }

      return text.join(" ").toLowerCase();
    };

    const generateTemplateTextToSearch = (template) => {
      const text = [];
      text.push(template.title);
      text.push(template.description.replace(/(<([^>]+)>)/gi, ""));
      text.push(template.bussiness_questions.join(" "));

      for (const v in vocsNames) {
        const voc = vocsNames[v].vocabulary;
        if (template[voc]) {
          for (const x in template[voc]) {
            text.push(template[voc][x].name);
          }
        }
      }

      return text.join(" ").toLowerCase();
    };

    const metrics1 = metrics
      //.filter((item) => !item.preview)
      .map((item) => ({
        ...item,
        connectors: item.connectors.map((c) => c.name),
      }))
      .filter((item) => item.connectors.includes(integrationId))
      .map((item) => ({
        ...item,
        text: generateMetricTextToSearch(item),
      }));
    //.filter((item) => item.text.includes(sq.toLowerCase()))

    const templates1 = templates
      .map((item) => ({
        ...item,
        connectors2: item.connectors.map((c) => c.name),
      }))
      .filter((item) => item.connectors2.includes(integrationId))
      .map((t) => ({
        ...t,
        wrapperClassName: "",
        className:
          "border border-[#E9EFF6] p-4 rounded-lg flex flex-row justify-start gap-x-3 hover:border-[#AFBACA] bg-white",
        text: generateTemplateTextToSearch(t),
      }));

    return {
      metrics: metrics1,
      templates: templates1,
    };
  };

  const base = useMemo(
    () => createBase(metrics.nodes, templates.nodes, node.integrationId),
    [metrics.nodes, templates.nodes, node.integrationId]
  );

  const data = useMemo(() => {
    return {
      metrics: base.metrics.filter((item) =>
        item.text.includes(sq.toLowerCase())
      ),
      templates: base.templates.filter((item) =>
        item.text.includes(sq.toLowerCase())
      ),
    };
  }, [base, sq]);

  const metrics1 = data.metrics.filter((item) => !item.preview);
  const metrics2 = data.metrics.filter((item) => !!item.preview);
  const templates1 = data.templates;

  const [isOpen, setIsOpen] = useState(false);
  const [subject, setSubject] = useState("");
  const [integration, setIntegration] = useState("");
  const [metric, setMetric] = useState("");

  const openPopupWith = (integration, metric) => {
    setSubject(`Integration: ${integration}  Metric: ${metric}`);
    setIntegration(integration);
    setMetric(metric);
    setIsOpen(true);
  };

  const closePopup = () => {
    setIsOpen(false);
  };

  const metrics1a = metrics1.map((item) => ({
    Comp: Metric,
    props: item,
  }));


  if ( metrics1.length > 0) {
    metrics1a.splice(7, 0, {
      Comp: BuildYourOwn,
      props: {
        title: "Your own Custom Metric",
        one_liner:
          "Use our Metric Builder to quickly and easily create your own metrics",
        integration: node.field_id,
      },
    });
  }

  return (
    <Layout showCta={false}>
      <Seo {...seo} />
      <div className="h-[70px]"></div>
      <div className=" max-w-screen-xl mx-auto px-6 xl:px-0">
        <div className="pt-[52px] pb-[47px]">
          <Link
            to={"/integrations/"}
            className="border py-[2px] px-2 bg-[#F0F3F9] text-[#727C8D]"
          >
            {"<-- Back to Integration Library"}
          </Link>
        </div>
        <Hero {...node} />

        <div className="border-[#DEE1E5] border-b my-[50px]"></div>

        <div className="space-y-[50px]">
          <SectionCards
            title={"Templates"}
            hasSearch={true}
            search={<SearchInput value={sq} onChange={handleChange} />}
            show={templates1.length > 0}
          >
            <Pagination
              Card={Template}
              groupSize={6}
              className="grid grid-cols-1 md:grid-cols-3 gap-4"
              items={templates1}
            >
              <RequestNew
                title={"Request new template"}
                form_title="Request a new template"
              />
            </Pagination>
          </SectionCards>

          <SectionCards title={"Metrics"} show={metrics1.length > 0}>
            <PaginationV2
              Card={Metric}
              items={metrics1a}
              className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-x-5 gap-y-5"
            >
              <RequestNew
                title={"Request new metric"}
                form_title="Request a new metric"
              />
            </PaginationV2>
          </SectionCards>

          <Modal isOpen={isOpen} onClose={closePopup}>
            <RequestForm
              title={"Request a new metric"}
              onClose={closePopup}
              subject={subject}
              integration={integration}
              metric={metric}
            />
          </Modal>

          <SectionCards
            title={"Metrics"}
            show={metrics1.length < 8 && metrics2.length > 0}
          >
            <Pagination
              Card={MetricPreview}
              cardProps={{ openPopupWith: openPopupWith }}
              items={metrics2}
              className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-x-5 gap-y-5"
            >
              <RequestNew title={"Request new metric"} />
            </Pagination>
          </SectionCards>

          {templates1.length === 0 &&
            metrics1.length === 0 &&
            metrics2.length === 0 && (
              <div className="pb-16 md:pb-[171px]">
                <Component data={noResults} context="integration" integration={node.field_id} />
              </div>
            )}
        </div>
      </div>

      <div className=" max-w-screen-xl px-6 lg:px-[80px] mx-auto space-y-16 lg:space-y-[142px] py-[120px]">
        <HowItWorksBlock className="space-y-6" context="integration" />
      </div>

      <div className=" max-w-screen-xl px-6 lg:px-[80px] mx-auto space-y-16 lg:space-y-[142px] my-16 ">
        <WhatIsItBlock />
      </div>
    </Layout>
  );
}

const SectionCards = ({
  title,
  children,
  hasSearch = false,
  search,
  show = true,
}) => {
  return show ? (
    <div className="space-y-6">
      <div className="flex flex-col-reverse lg:flex-row justify-between gap-y-4">
        <h2 className="text-xl leading-[28px] font-medium">{title}</h2>
        {hasSearch && <div>{search}</div>}
      </div>

      {children}
    </div>
  ) : (
    false
  );
};

const Hero = ({ title, label, body, ...props }) => {
  const image = {
    ...props.image,
    src: props?.relationships?.image?.localFile?.publicURL,
  };

  return (
    <div className="flex flex-col lg:flex-row justify-start gap-x-[50px] text-[#3D434C]">
      <div className="w-[60px] h-[60px] flex flex-col items-center justify-around">
        <img {...image} alt={image.alt} className="block  h-[60px] min-w-[60px] "  />
      </div>
      <div className="space-y-[40px]">
        <div className="space-y-3">
          <h1 className="text-[50px] leading-[50px] font-semibold ">{title}</h1>
          {label && (
            <div className="bg-[#C2E6FF] inline-block text-xs font-medium rounded-[2px] py-[2px] px-2 text-[#1177C0]">
              {label}
            </div>
          )}
        </div>
        <div
          dangerouslySetInnerHTML={{ __html: body.processed }}
          className="text-base"
        />
      </div>
    </div>
  );
};

const Integrations = ({ terms, nodes }) => {
  return (
    <section className="py-32">
      <div className="max-w-screen-xl mx-auto text-center">
        <h2 className="text-4xl my-32 font-bold">Integrations here</h2>
        <div className="flex flex-row justify-between">
          <div className="w-1/4">
            <Integrations.Terms terms={terms} />
          </div>
          <div className="w-3/4">
            <Integrations.Nodes nodes={nodes} />
          </div>
        </div>
      </div>
    </section>
  );
};

Integrations.Terms = ({ terms }) => {
  return (
    <div className="text-left">
      <h3>Categories</h3>
      {/* <pre>{JSON.stringify(terms,null,3)}</pre> */}
      <div>
        {terms.map((t, index) => (
          <div className="" key={index}>
            {t.name}
          </div>
        ))}
      </div>
    </div>
  );
};

Integrations.Nodes = ({ nodes }) => {
  return (
    <div className="text-left">
      <h2>Integration</h2>
      <div>
        {nodes.map((t, index) => (
          <div key={index}>
            {/* <pre className="text-xs">{JSON.stringify(t, null, 3)}</pre> */}
            <IntegrationCard {...t} />
          </div>
        ))}
      </div>
    </div>
  );
};

const IntegrationCard = ({ title, path, ...props }) => {
  const image = {
    ...props.image,
    src: props?.relationships?.image?.localFile?.publicURL,
  };

  const tags = props.relationships.tags;
  return (
    <Link to={path.alias}>
      <div className="border bg-gray-400 rounded-xl p-4">
        <div>
          <img {...image} alt={image.alt} />
        </div>
        <div>{title}</div>
        <div className="flex flex-row gap-x-4">
          {tags.map((tag, index) => (
            <div key={index}>{tag.name}</div>
          ))}
        </div>
      </div>
    </Link>
  );
};


const Component = ({ data, context, ...props }) => {
  //console.log( data )
  let comp = "";

  switch (data.__typename) {
    
    case "block_content__section_content":
      comp = (
        <BlockContentSectionContent data={data} context={context} {...props} />
      );
      break;

    default:
      comp = <pre>{JSON.stringify(data, null, 3)}</pre>;
      break;
  }

  return <>{comp}</>;
};


export default IntegrationsPage;

//export const Head = () => <title>Overview Page</title>

export const query = graphql`
  query integrationPage($id: String!) {
    node: nodeIntegration(id: { eq: $id }) {
      ...NodeIntegration
    }
    templates: allMarketplaceTemplate(sort: { fields: title, order: ASC }) {
      nodes {
        id
        title
        slug
        one_liner

        bussiness_questions
        description
        category {
          id
          name
        }
        connectors {
          id
          name
        }
        persona {
          id
          name
        }
        workflows {
          id
          name
        }
        metrics {
          id
          _id
          title
          one_liner
          slug
          image {
            id
            height
            url
            alt
          }
        }
      }
    }
    metrics: allMarketplaceMetric(sort: { fields: title, order: ASC }) {
      nodes {
        id
        _id
        title
        slug
        one_liner
        alternative_names
        preview
        image {
          id
          height
          url
          alt
        }
        category {
          id
          name
        }
        connectors {
          id
          name
        }
        persona {
          id
          name
        }
        workflows {
          id
          name
        }
        tags {
          id
          name
        }
      }
    }
    noResults: blockContentSectionContent(
      id: { eq: "a7fe77ba-1228-59ef-96fc-156171356f45" }
    ) {
      __typename
      id
      info
      relationships {
        content: field_content {
          ...ParagraphGenericBlock
        }
      }
    }
  }
`;
